<template>
  <div>
    <el-drawer
      :title="modeType == 'add' ? $t('home.Custom Customers') : $t('home.Edit')+$t('home.Custom Customers')"
      :visible.sync="addCustomerShow"
      size="400px"
      :before-close="CloseDrawer"
      v-move-outside
      :modal="modeType != 'add'"
    >
      <el-form 
        v-if="addCustomerShow"
        :model="form" 
        :rules="rules" 
        ref="createForm" 
        label-width="0"
        size="small"
        class="drawerForm">
        <div class="drawerForm_box">
          <div class="flexFormBox">
            <el-form-item prop="aliasName">
              <div class="title">{{ $t('home.aliasName') }}</div>
              <el-input 
                v-model="form.aliasName" 
                :placeholder="$t('home.aliasName')" 
                @blur="getTextImages"
              />
            </el-form-item>
            <div v-if="!imageUrl" class="imageUrl">
              <i class="iconfont icon-Plus"></i>
              Logo
            </div>
            <img v-else :src="imageUrl" />
          </div>
          <el-form-item prop="name">
            <div class="title">{{ $t('home.companyName') }}</div>
            <el-input v-model="form.name" :placeholder="$t('home.companyName')" />
          </el-form-item>
          <el-form-item prop="email">
            <div class="title">{{ $t('home.email') }}</div>
            <el-input v-model="form.email" :placeholder="$t('home.email')" />
          </el-form-item>
          <el-form-item prop="vat">
            <div class="title">{{ $t('home.VATNo') }}</div>
            <el-input v-model="form.vat" :placeholder="$t('home.VATNo')" />
          </el-form-item>
          <el-form-item prop="contact">
            <div class="title">{{ $t('home.contact') }}</div>
            <el-input v-model="form.contact" :placeholder="$t('home.contact')" />
          </el-form-item>
          <el-form-item prop="country">
            <div class="title">{{ $t('home.country') }}</div>
            <el-select
              v-model="form.country"
              filterable
              appendToBody
              :placeholder="$t('customerInfo.Choose the country')"
            >
              <el-option
                v-for="item in country"
                :key="item.value"
                :label="item.key"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="timeZone">
            <div class="title">{{ $t('home.timezone') }}</div>
            <el-select
              v-model="form.timeZone"
              filterable
              :placeholder="$t('customerInfo.Choose the timezone')"
              appendToBody
            >
              <el-option
                v-for="item in timezone"
                :key="item.value"
                :label="item.key"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="address">
            <div class="title">
              {{ $t('CompanyEdit.Address') }}
              <img class="mapSelectImage" @click="mapSelectEvent" src="https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/selectMap.png" />
            </div>
            <el-input v-model="form.address" disabled :placeholder="$t('Please select an address on the map')" autocomplete="off" />
          </el-form-item>
          <el-form-item prop="website">
            <div class="title">{{ $t('CompanyEdit.Website') }}</div>
            <el-input v-model="form.website" :placeholder="$t('CompanyEdit.Website')" />
          </el-form-item>
          <div class="flexFormBox">
            <el-form-item prop="defaultAdminName">
              <div class="title">{{ $t('Custom administrator name') }}</div>
              <el-input 
                v-model="form.defaultAdminName" 
                :placeholder="$t('Custom administrator name')" 
                @blur="getTextImages2"
              />
            </el-form-item>
            <div v-if="!imageAdminUrl" class="imageUrl">
              <i class="iconfont icon-Plus"></i>
              Avatar
            </div>
            <img v-else :src="imageAdminUrl" />
          </div>
          <el-form-item prop="defaultAdminEmail">
            <div class="title">{{ $t('Custom administrator email') }}</div>
            <el-input v-model="form.defaultAdminEmail" :placeholder="$t('Custom administrator email')" />
          </el-form-item>
        </div>
        <div class="bottomBox" v-if="modeType == 'add'">
          <el-form-item v-if="optionsSelect.length">
            <div class="title">{{ $t('home.exhibition') }}</div>
            <chat-select
              v-model="form.groupUsers"
              multiple
              collapse-tags
              appendToBody
              :placeholder="$t('home.showTo')"
              clearable
            >
              <chat-option
                v-for="item in optionsSelect"
                :key="item.openId"
                :label="item.nickName"
                :value="item.openId"
              >
                <span style="float: left">{{ item.nickName }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.position
                }}</span>
              </chat-option>
            </chat-select>
          </el-form-item>
        </div>
      </el-form>
      <div class="drawerFooter">
        <el-button class="cancel_button" size="small" @click="CloseDrawer">{{ $t('Forwarder.Cancel') }}</el-button>
        <el-button 
          type="primary" 
          size="small"
          @click="addCustomer"
          :loading="buttonLoading"
        >{{ modeType == 'add' ? $t('home.createConnectionNow') : $t('home.Edit') }}</el-button>
      </div>
    </el-drawer>
    <mapSelect ref="mapSelect" @updateAddress="updateAddress" />
  </div>
</template>

<script>
import {
  getCompanyEmployeeList,
  addVirtualCustomer,
  editVirtualCustomer
} from '@/api/customer';
import { getTextImage, getTextUserImage } from "@/api/common";
import { uploadFile } from '@/api/files';
import { mapGetters } from 'vuex';

export default {
  name: 'createCustomer',
  components: {
    cropper: () => import('@/components/cropper/cropper.vue'),
  },
  data() {
    return {
      modeType: "",
      buttonLoading: false,
      addCustomerShow: false,
      imageUrl: '',
      companyImageFlow: "",
      imageAdminUrl: "",
      adminImageFlow: "",
      form: {
        address: "",
        position: "",
        name: "",
        aliasName: "",
        email: "",
        contact: "",
        vat: "",
        country: "",
        timeZone: "",
        companyLogoId: "",
        defaultAdminName: "",
        defaultAdminEmail: "",
        defaultAdminAvatarId: "",
        groupUsers: []
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t('home.Please enter')+this.$t('home.companyName'),
          },
        ],
        aliasName: [
          {
            required: true,
            message: this.$t('home.Please enter')+this.$t('home.aliasName'),
          },
        ],
        website: [{
          pattern: /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/,
          message: window.vm.$t('people.Please enter the correct official website address'),
          trigger: 'blur',
        }],
        email: [
          {
            required: true,
            message: this.$t('home.Please enter')+this.$t('home.email'),
          },
        ],
        contact: [
          {
            required: true,
            message: this.$t('home.Please enter')+this.$t('home.contact'),
          },
        ],
        vat: [
          {
            required: true,
            message: this.$t('home.Please enter')+this.$t('home.VATNo'),
          },
        ],
        country: [
          {
            required: true,
            message: this.$t('home.Please select')+this.$t('home.country'),
          },
        ],
        timeZone: [
          {
            required: true,
            message: this.$t('home.Please select')+this.$t('home.timezone'),
          },
        ],
        address: [{
          required: true,
          message: this.$t('home.Please select')+this.$t('CompanyEdit.Address'),
        }],
        defaultAdminName: [
          {
            required: true,
            message: this.$t('home.Please enter')+this.$t('Custom administrator name'),
          },
        ],
        defaultAdminEmail: [
          {
            required: true,
            message: this.$t('home.Please enter')+this.$t('Custom administrator email'),
          },
        ],
        defaultAdminAvatarId: [
          {
            required: true,
            message: this.$t('home.Please select')+this.$t('memberList.manage')+this.$t('avatar'),
          },
        ]
      },
      optionsSelect: [],
      loadingConnection: false,
    };
  },
  computed: {
    ...mapGetters(['country', 'timezone', 'businessUserId', 'companyId'])
  },
  methods: {
    //由于mounted不执行，所以用el-dialog回调函数
    open(row, mode = 'add') {
      this.modeType = mode;
      this.addCustomerShow = true;
      if(mode == 'add') {
        getCompanyEmployeeList(this.companyId).then((response) => {
          if (!response) return;
          this.optionsSelect = response.memberList.filter((item) => {
            return item.openId != this.businessUserId;
          });
        }).catch((error) => {});
        return;
      }
      this.imageUrl = row.companyLogoUrl;
      this.imageAdminUrl = row.companyManager.avatarFileUrl;
      this.form = {
        address: row.address,
        position: row.position,
        name: row.name,
        aliasName: row.aliasName,
        email: row.email,
        contact: row.contact,
        vat: row.vat,
        country: row.country,
        timeZone: row.timeZone, 
        companyLogoId: row.companyLogoId,
        defaultAdminName: row.companyManager.username,
        defaultAdminEmail: row.companyManager.email,
        defaultAdminAvatarId: row.companyManager.avatarFileId,
        companyCustomerId: row.companyCustomerId
      }
      this.getTextImages();
      this.getTextImages2();
    },
    mapSelectEvent() {
      this.$refs.mapSelect.open(this.form);
    },
    updateAddress(obj) {
      this.form.address = obj.address;
      this.form.position = obj.addressPosition.join(",");
    },
    getTextImages() {
      if(!this.form.aliasName) {
        this.imageUrl = "";
        return;
      }
      getTextImage(this.form.aliasName).then(data => {
        this.imageUrl = URL.createObjectURL(data);
        this.companyImageFlow = data;
      })
    },
    getTextImages2() {
      if(!this.form.defaultAdminName) {
        this.imageAdminUrl = "";
        return;
      }
      getTextUserImage(this.form.defaultAdminName).then(data => {
        this.imageAdminUrl = URL.createObjectURL(data);
        this.adminImageFlow = data;
      })
    },
    CloseDrawer() {
      this.addCustomerShow = false;
      this.imageUrl = "";
      this.imageAdminUrl = "";
      this.form = {
        address: "",
        position: "",
        name: "",
        email: "",
        contact: "",
        vat: "",
        country: "",
        timeZone: "",
        companyLogoId: "",
        defaultAdminName: "",
        defaultAdminEmail: "",
        defaultAdminAvatarId: "",
        groupUsers: []
      }
    },
    addCustomer() {
      this.$refs.createForm.validate((valid) => {
        if (!valid) return;
        this.buttonLoading = true;
        let data_up = new FormData();
        data_up.append('files', this.companyImageFlow, `${this.form.name}.png`);
        data_up.append('configCode', 'companyProfile');
        uploadFile(data_up)
          .then((data) => {
            if (!data[0]) return;
            this.form.companyLogoId = data[0];
            let data_up_user = new FormData();
            data_up_user.append('files', this.adminImageFlow, `${this.form.defaultAdminName}.png`);
            data_up_user.append('configCode', 'userImage');
            uploadFile(data_up_user)
              .then((datas) => {
                if (!datas[0]) return;
                this.form.defaultAdminAvatarId = datas[0];
                if(this.modeType == 'add') {
                  addVirtualCustomer({
                    ...this.form,
                    groupUsers: this.form.groupUsers.join(",")
                  }).then(() => {
                    this.$message.success(this.$t('home.Createdsuccessfully'));
                    this.CloseDrawer();
                    this.$emit("close");
                    this.$store.state.user.applyType = new Date().getTime();
                  }).finally(() => (this.buttonLoading = false));
                } else {
                  editVirtualCustomer({
                    ...this.form
                  }).then(() => {
                    this.$message.success(this.$t('home.editSuccess'));
                    this.CloseDrawer();
                    this.$store.state.user.applyType = new Date().getTime();
                  }).finally(() => (this.buttonLoading = false));
                }
              })
              .catch((error) => {});
          })
          .catch((error) => {});
      });
    }
  },
};
</script>

<style lang="less" scoped>
.flexFormBox {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  /deep/ .el-form-item {
    flex: 1;
  }
  .imageUrl {
    width: 87px;
    height: 56px;
    background: #F7F9FC;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E0E4EA;
    font-size: 14px;
    line-height: normal;
    border: 1px dashed #E3E9F1;
    border-radius: 4px;
    i {
      font-size: 12px;
    }
  }
  img {
    width: 87px;
    height: 56px;
    object-fit: contain;
    border-radius: 4px;
  }
}
/deep/ .el-drawer__body {
  background: #F7F9FC;
  .drawerForm {
    padding: 0 0 20px 0;
  }
  .drawerForm_box {
    background: #ffffff;
    padding: 20px 20px 5px 20px;
  }
  .bottomBox {
    padding: 20px;
    .title {
      font-weight: 500;
      color: #122545;
    }
  }
  .customerInfoBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .name {
        color: #122545;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 3px;
      }
      .title {
        margin-bottom: 0;
      }
    }
    .el-avatar {
      width: 55px;
      height: 42px;
      border-radius: 4px;
      img {
        width: 100%;
      }
    }
  }
}
.companyText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  .cantfind {
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
    color: #637381;
    text-align: right;
    a {
      color: #076F49;
      cursor: pointer;
    }
  }
}

.invitation_box {
  margin-top: 3px;
  .form_item {
    margin-bottom: 10px;
  }
}
.formImage {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 2px solid #ffffff;
  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(18, 37, 69, .6);
    color: #ffffff;
    text-align: center;
    line-height: 142px;
    opacity: 0;
    cursor: pointer;
    transition: all .3s;
    i {
      font-size: 30px;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
  &:hover {
    .mask {
      opacity: 1;
    }
  }
  .el-image {
    width: 100%;
    height: 100%;
  }
}
.mapSelectImage {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}
</style>
