<template>
  <el-drawer
    :title="$t('home.connectionTitle')"
    :visible.sync="addCustomerShow"
    size="400px"
    :before-close="CloseDrawer"
    @open="open()"
    v-move-outside
  >
    <el-form 
      :model="form" 
      :rules="rules" 
      ref="createForm" 
      label-width="0"
      size="small"
      class="drawerForm">
      <div class="drawerForm_box">
        <el-form-item prop="" class="is-required">
          <div class="title">{{ $t('home.companyName') }}</div>
          <el-input 
            v-model="companyCode" 
            :placeholder="$t('home.Please enter the company invitation code')" 
            @blur="getCodeCompany" 
            @keyup.enter.native="getCodeCompany"
          />
          <div class="companyText">
            <p class="cantfind">
              {{ $t('home.cantfind') }}
              <a @click="() => this.$emit('openInvitationDialog', true)">
                {{ companyCurrentType == 0 || companyCurrentType == 2 ? $t('home.inviteSupplier') : $t('home.inviteCustomers') }}
              </a>
            </p>
            <p class="cantfind" v-permission="'creater:custom:customer'">
              <a @click="openCustomCustomer">{{ $t('home.Custom Customers') }}</a>
            </p>
          </div>
        </el-form-item>
        <template v-if="form.companyId">
          <el-form-item>
            <div class="customerInfoBox">
              <div class="left">
                <div class="name">{{ form.company }}</div>
                <div class="title">{{ $t('home.companyName') }}</div>
              </div>
              <el-avatar :src="imageUrl" fit="cover" />
            </div>
          </el-form-item>
          <el-form-item>
            <div class="title">{{ $t('home.email') }}</div>
            <div class="formText">{{ form.email }}</div>
          </el-form-item>
          <el-form-item>
            <div class="title">{{ $t('home.VATNo') }}</div>
            <div class="formText">{{ form.vat }}</div>
          </el-form-item>
          <el-form-item>
            <div class="title">{{ $t('home.contact') }}</div>
            <div class="formText">{{ form.contact }}</div>
          </el-form-item>
          <el-form-item>
            <div class="title">{{ $t('home.country') }}</div>
            <div class="formText">{{ isFindData(country, form.country, 'value', 'key') }}</div>
          </el-form-item>
          <el-form-item>
            <div class="title">{{ $t('home.timezone') }}</div>
            <div class="formText">{{ isFindData(timezone, form.timezone, 'value', 'key') }}</div>
          </el-form-item>
        </template>
      </div>
      <div class="bottomBox" v-if="form.companyId">
        <el-form-item v-if="optionsSelect.length">
          <div class="title">{{ $t('home.exhibition') }}</div>
          <el-select
            v-model="form.showList"
            multiple
            collapse-tags
            appendToBody
            :maxCollapseTags="2"
            :placeholder="$t('home.showTo')"
            clearable
          >
            <el-option
              v-for="item in optionsSelect"
              :key="item.openId"
              :label="item.nickName"
              :value="item.openId"
            >
              <span style="float: left">{{ item.nickName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.position
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="title">{{ $t('InformationCenter.Notes') }}</div>
          <el-input
            type="textarea"
            :placeholder="$t('order.Please input content')"
            :maxlength="500"
            v-model="form.remark">
          </el-input>
        </el-form-item>
      </div>
    </el-form>
    <div class="drawerFooter">
      <el-button class="cancel_button" size="small" @click="CloseDrawer">{{ $t('Forwarder.Cancel') }}</el-button>
      <el-button 
        type="primary" 
        size="small"
        @click="addCustomer"
        :loading="loadingConnection"
        :disabled="loadingConnection"
      >{{ $t('home.createConnectionNow') }}</el-button>
    </div>
    <el-dialog
      :title="$t('people.createAccount')"
      :visible.sync="invitationShow"
      @close="closeInvitation()"
      width="358px"
      append-to-body
      v-move-outside
    >
      <div class="invitation_box">
        <div class="form_item">
          <el-input
            v-model="invitationForm.name"
            :placeholder="$t('home.CustomerName')"
            clearable
            size="small"
          />
        </div>
        <div class="form_item">
          <el-input
            v-model="invitationForm.email"
            :placeholder="$t('home.Email')"
            clearable
            size="small"
          />
        </div>
      </div>
      <div slot="footer">
        <el-button class="cancel_button" size="small" @click="copy">{{ $t('home.copylink') }}</el-button>
        <el-button
          type="primary"
          size="small"
          @click="invitation"
          :icon="isDisabled ? 'el-icon-loading' : 'el-icon-s-promotion'"
          :disabled="isDisabled"
        >
          {{$t('InformationCenter.Send')}}
        </el-button>
      </div>
    </el-dialog>
    <createCustomCustomer ref="createCustomCustomer" @close="CloseDrawer" />
  </el-drawer>
</template>

<script>
import {
  getCompanyList,
  createCustomerConnection,
  getCompanyEmployeeList,
  getCompanyByInviteCode
} from '@/api/customer';
import { downloadUserPic } from '@/api/files';
import { mapGetters } from 'vuex';
import { getRegisterLink, sendEmail } from '../../api';
import { getOfficeCompany } from '../../api/customer';
import { parse } from 'xlsx';
import { isFindData } from "@/utils/utils.js";

import createCustomCustomer from "./createCustomCustomer";

export default {
  name: 'createCustomer',
  components: {
    createCustomCustomer
  },
  data() {
    return {
      imageUrl: '',
      isFindData: isFindData,
      companyCode: "",
      form: {
        company: '',
        companyId: '',
        showList: [],
        remark: ""
      },
      rules: {},
      invitationForm: {
        name: '',
        email: ''
      },
      isDisabled: false,
      companyType: '',
      loadingConnection: false,
      optionsSelect: []
    };
  },
  props: ['addCustomerShow', 'invitationShow'],
  computed: {
    ...mapGetters(['timezone', 'country', 'businessUserId', 'companyId', 'manageCompany', 'companyCurrentType'])
  },
  watch: {
    'form.company'(newData, oldData) {
      if (newData == '') {
        this.form = {
          company: this.form.company,
          companyId: '',
          showList: [],
        };
        this.imageUrl = '';
      }
    },
  },
  methods: {
    //由于mounted不执行，所以用el-dialog回调函数
    open() {
      //初始化公司人员头像等信息
      getOfficeCompany(this.companyId).then((res) => {
        this.companyType = res.companyType;
        getCompanyEmployeeList(this.companyId)
          .then((response) => {
            if (!response) return;
            this.optionsSelect = response.memberList.filter((item) => {
              return item.openId != this.businessUserId;
            });
          })
          .catch((error) => {});
      });
    },
    openCustomCustomer() {
      this.$refs.createCustomCustomer.open();
    },
    getCodeCompany() {
      if(!this.companyCode) return;
      getCompanyByInviteCode(this.companyCode).then((item) => {
        this.form.company = item.name;
        this.form.companyId = item.companyId;
        this.form.country = item.country;
        this.form.email = item.email;
        this.form.contact = item.contact;
        this.form.vat = item.vat;
        this.form.timezone = item.timeZone;
        this.form.logoId = item.companyLogoId;
        this.imageUrl = item.companyLogoUrl;
      })
    },
    querySearchAsync(queryString, cb) {
      if(!queryString) {
        cb([]);
        return;
      }
      var data_name = {
        name: this.form.company,
        containCustomer: false,
      };
      getCompanyList(data_name)
        .then((response) => {
          if (!response) return;
          let arr = [];
          response.list.forEach((item) => {
            if(item.companyType !== this.companyType) arr.push(item);
          });
          cb(arr);
        })
        .catch((error) => {
          this.$message.error('querySearchAsync error');
        });
    },
    handleSelect(item) {
      this.form.company = item.name;
      this.form.companyId = item.companyId;
      this.form.country = item.country;
      this.form.email = item.email;
      this.form.contact = item.contact;
      this.form.vat = item.vat;
      this.form.timezone = item.timeZone;
      this.form.logoId = item.companyLogoId;
      this.imageUrl = item.companyLogoUrl;
    },
    addCustomer() {
      var data = {
        customerId: this.form.companyId,
        remark: this.form.remark,
        inviteCode: this.companyCode,
        companyUsers: this.optionsSelect.length
          ? this.form.showList.join(',')
          : this.businessUserId,
      };
      if(!this.form.showList.length) {
        data.companyUsers = this.businessUserId;
      }
      if (data.customerId) {
        this.loadingConnection = true;
        createCustomerConnection(data)
          .then(() => {
            this.$message.success(this.$t('home.createCustomerSuccesfully'));
            this.CloseDrawer();
          })
          .finally(() => (this.loadingConnection = false));
      } else {
        this.$message.error(this.$t('home.Pleasecompletetheinformation'));
      }
    },
    invitation() {
      if (this.invitationForm?.email && this.invitationForm?.name) {
        var emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
        if(!emailReg.test(this.invitationForm?.email)) {
          this.$message.warning(this.$t('login.PleaseinputaSuccessEmail'))
          return;
        }
        this.isDisabled = true;
        sendEmail(this.invitationForm)
          .then(() => {
            this.$message.success(this.$t('home.Invitation sent successfully'));
            this.invitationShow = false;
            this.invitationForm = {
              name: '',
              email: ''
            };
          })
          .finally(() => {
            this.isDisabled = false;
          });
      } else {
        this.$message.error(this.$t('home.Pleasecompletetheinformation'));
      }
    },
    copy() {
      getRegisterLink().then(
        (data) => {
          const theClipboard = navigator.clipboard;
          theClipboard.writeText(data).then(() => {
            this.$message.success(this.$t('home.Copysucceeded'));
          });
        }
      );
    },
    closeInvitation() {
      this.$emit('openInvitationDialog', false);
      this.invitationForm = {
        name: '',
        email: ''
      };
    },
    CloseDrawer() {
      this.$emit('handleClose');
      this.form = {
        company: '',
        companyId: '',
        showList: [],
      };
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-drawer__body {
  background: #F7F9FC;
  .drawerForm {
    padding: 0 0 20px 0;
  }
  .drawerForm_box {
    background: #ffffff;
    padding: 20px 20px 5px 20px;
  }
  .bottomBox {
    padding: 20px;
    .title {
      font-weight: 500;
      color: #122545;
    }
  }
  .customerInfoBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .name {
        color: #122545;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 3px;
      }
      .title {
        margin-bottom: 0;
      }
    }
    .el-avatar {
      width: 55px;
      height: 42px;
      border-radius: 4px;
      img {
        width: 100%;
      }
    }
  }
}
.companyText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  .cantfind {
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
    color: #637381;
    text-align: right;
    white-space: nowrap;
    a {
      color: #076F49;
      cursor: pointer;
      white-space: nowrap;
    }
  }
}

.invitation_box {
  margin-top: 3px;
  .form_item {
    margin-bottom: 10px;
  }
}
</style>
